import React, {useState} from "react";
import {Link} from "react-router-dom";
import Logo from "../images/logo/ezcarrentals-Newlogo.png";
import {FaEnvelopeOpenText, FaPhoneVolume} from "react-icons/fa";

function Navbar() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const emailAddress = "ezcarrentalsmelbourne@gmail.com";
    const phoneNumber = "0400 920 512";

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };
    const handleServiceClick = () => {
        navigateAndScroll(".pick-container");
    };

    const handleTestimonialsClick = () => {
        navigateAndScroll(".testimonials-section");
    };

    const handleContactusClick = () => {
        navigateAndScroll(".contact-page");
    };

    const navigateAndScroll = (selector) => {
        if (window.location.pathname !== "/") {
            window.location.assign("/"); // Navigate to the homepage
            window.addEventListener("load", () => {
                setTimeout(() => {
                    scrollToElement(selector);
                }, 100); // Adjust timeout as needed to ensure the page has loaded
            });
        } else {
            setTimeout(() => {
                scrollToElement(selector);
            }, 100); // Add slight delay to ensure element is rendered
        }
    };


    const scrollToElement = (selector) => {
        const element = document.querySelector(selector);
        if (element) {
            element.scrollIntoView({behavior: "smooth"});
        }
    };

    return (
        <>


      <nav>
        

        {/* desktop */}

          <div className='navbar__content'>
           <div className='top-bar'>
               <p>LIMITED AVAILABILITY. SECURE YOUR VEHICLE TODAY</p>

           </div>
            <div className='navbar'>
                <div className='navbar__img'>
            <Link to='/' onClick={() => window.scrollTo(0, 0)}>
              <img src={Logo} alt='logo-img'/>
            </Link>
          </div>
                <ul className='navbar__links'>
            <li>
              <Link
                  className='home-link'
                  to='/'
                  onClick={() => window.scrollTo(0, 0)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link className='about-link' to='/' onClick={handleServiceClick}>
                Our Services
              </Link>
            </li>
            <li>
              {" "}
                <Link to='/' onClick={handleTestimonialsClick}>
                Testimonials
              </Link>
            </li>
            <li>
              {" "}
                <Link to='/' onClick={handleContactusClick}>
                Contact us
              </Link>
            </li>
            <li>
              <Link to='/about-us'>About Us</Link>
            </li>
            <li>
              <Link to='/blog'>Blogs</Link>
            </li>
          </ul>
                <div className='hamburger' onClick={toggleMobileMenu}>
            <div></div>
            <div></div>
            <div></div>
          </div>
            </div>

        </div>

          {/* *******************************mobile menu************************************************** */}

          <ul className={`mobile-menu ${isMobileMenuOpen ? 'active' : ''}`}>
          <li>
            <Link to='/' onClick={() => {
                setIsMobileMenuOpen(false);
                window.scrollTo(0, 0);
            }}>
              Home
            </Link>
          </li>
          <li>
            <Link to='/' onClick={() => {
                setIsMobileMenuOpen(false);
                handleServiceClick();
            }}>
              Our Services
            </Link>
          </li>
          <li>
            <Link to='/' onClick={() => {
                setIsMobileMenuOpen(false);
                handleTestimonialsClick();
            }}>
              Testimonials
            </Link>
          </li>
          <li>
            <Link to='/' onClick={() => {
                setIsMobileMenuOpen(false);
                handleContactusClick();
            }}>
              Contact us
            </Link>
          </li>
          <li>
            <Link to='/about-us' onClick={() => setIsMobileMenuOpen(false)}>
              About Us
            </Link>
          </li>
          <li>
            <Link to='/blog' onClick={() => setIsMobileMenuOpen(false)}>
            Blog
            </Link>
          </li>
        </ul>
          {/* ********************************************************************************* */}


      </nav>
    </>
    );
}

export default Navbar;
